<template>
  <div class="container">
    <div class="index_header">
      <!-- <div class="current_city">
        {{ cityName || '定位失败' }}
      </div> -->
      <div class="operate mb18 mt18">
        <div v-if="cityName" class="left">当前城市: {{ cityName }}</div>
        <div v-else class="left">定位失败</div>
        <div class="right">
          <span class="gps">GPS定位</span>
          <!-- <span class="city ml8 mr8">{{ cityName || '定位失败' }}</span> -->
          <span class="icon">
            <img
              @click="handleLocation"
              :src="require('@img/city.png')"
              class="icon_reset"
            />
          </span>
        </div>
      </div>
    </div>

    <!-- {{ JSON.stringify(indexList) }} -->
    <div class="index_bar">
      <IndexBar :index-list="indexList">
        <div v-for="model in indexList" :key="model">
          <IndexAnchor :index="model">
            {{ model }}
          </IndexAnchor>
          <Cell
            @click="selectedCity(item)"
            v-for="item in citys[model]"
            :key="item.cityId"
            :title="item.cityName"
          />
        </div>
      </IndexBar>
    </div>
  </div>
</template>

<script>
import api from '@/api';
import { IndexBar, IndexAnchor, Cell } from 'vant';
import { mapState, mapMutations, mapActions } from 'vuex';
import WxTool from '@/utils/wx-tool';

export default {
  components: {
    IndexBar,
    IndexAnchor,
    Cell,
  },
  data () {
    return {
      indexList: [],
      citys: {},
      cityName: ''
    };
  },
  computed: {
    ...mapState({
      cityInfo: (store) => store.city.cityInfo,
    }),
  },
  created () {
    // 获取城市列表
    this.getAllCitys();
    this.cityName = sessionStorage.getItem('cityName') || '定位失败'
  },
  methods: {
    // ...mapMutations('city', [
    //   'updateCityInfo',
    // ]),
    // ...mapActions('city', [
    //   'getCurrentCityInfo',
    // ]),

    location () {
      const instance = WxTool.getInstance();
      // 定位
      instance.getLocation((res) => {
        console.log('getLocation Res', res);
        if (res.longitude && res.latitude) {
          this.longitude = res.longitude
          this.latitude = res.latitude
          sessionStorage.setItem('longitude', res.longitude)
          sessionStorage.setItem('latitude', res.latitude)

          instance.getCurrentCity(res.latitude, res.longitude, (cityNameRes) => {
            console.log('cityName', cityNameRes);
            this.cityName = cityNameRes;
            sessionStorage.setItem('cityName', cityNameRes)
            this.$router.back();
          });
        } else {
          console.log('没有定位')
          // this.getInfusionList()
          this.cityName = ''
        }
      });
    },

    // 从城市列表中选择了城市
    selectedCity (city) {
      // 更新城市信息
      // this.updateCityInfo({ ...city });
      sessionStorage.setItem('cityName', city.cityName)
      // 回到上一页
      this.$router.back();
    },

    // 重新定位
    handleLocation () {
      this.location()
    },

    // 获取全部城市
    async getAllCitys () {
      const res = await api.getCitys();
      const citys = res.data

      const obj = {};
      citys.forEach((item) => {
        if (!obj[item.cityCode]) {
          obj[item.cityCode] = [];
        }
        const {
          // name: cityName, provincesId, cityId, standardCode: cityCode,
          cityCode, cityName
        } = item;
        obj[item.cityCode].push({
          cityName, cityCode
        });
      });
      this.indexList = Object.keys(obj).sort();
      this.citys = { ...obj };
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
